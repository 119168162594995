import { useState, useEffect } from 'react';
import {
    Form,
    DefaultFormGroup,
    IsRequired,
    MaxLength,
    ResponsiveButton,
    LeaveBlocker,
    SnackbarManager,
} from '@floriday/floriday-ui';
import {
    SupplyFilterType,
    FilterOption,
    FilterSetDetails,
    SupplyRangeFilterType,
    FilterItem,
    RangeFilterItem,
    SearchFilterItem,
    SearchRangeFilterItem,
    SupplyFilterSetting,
    ActionResult,
} from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import AuctionPreparationClient from '@api/AuctionPreparationClient';
import { authenticatedRequest, handleApiGetError } from '@api/helper';
import { FilterPanelSetting, filterPanelSettings } from '@constants/filterPanelSettings';
import StaticFilterRow from '@features/Filtering/Components/StaticFilterRow/StaticFilterRow';
import { useSupplyFilterType } from '@features/Filtering/Hooks/useSupplyFilterType';
import PageHeader from '@root/Components/PageHeader/PageHeader';
import useDirtyMarker from '@root/Hooks/useDirtyMarker';
import { namespaces } from '@root/i18n';
import { useFeatureFlags } from '@store/Providers/FeatureFlagsProvider';
import { useSearchCommandContext } from '@store/Providers/SearchCommandProvider';
import { useUserSettings } from '@store/Providers/UserSettingsProvider';
import { getAvailabilityOptions, getMaingroupOptions } from '@utils/FilterOptionUtils';
import { sharedStyles } from '@utils/SharedStyles';
import { getSupplyRangeFilterType, getSupplyFilterTypeByString } from '@utils/SupplyFilterTypeUtils';
import { getSupplyFilterTypeTranslation, getFilterSetMessagesTranslation } from '@utils/TranslationsUtils';
import { useStyles } from './FilterSetDetailStyles';

export default function FilterSetDetail() {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation([namespaces.general, namespaces.supply]);
    const { classes } = useStyles();
    const { classes: sharedClasses } = sharedStyles();
    const navigate = useNavigate();
    const dirtyMarker = useDirtyMarker();
    const client = AuctionPreparationClient.instance.getApiClient();
    const [defaultFilterTypeOrder, setDefaultFilterTypeOrder] = useState<SupplyFilterType[]>([]);
    const markingFilterOptions = getAvailabilityOptions(SupplyFilterType.Marking).map(m => ({
        key: m.key,
        name: m.name,
    }));

    const presaleFilterOptions = getAvailabilityOptions(SupplyFilterType.Presale).map(m => ({
        key: m.key,
        name: m.name,
    }));

    useQuery(['supply-filter-default'], () => authenticatedRequest(getSupplyFilterDefault), {
        onError: error => {
            handleApiGetError(error);
        },
        onSuccess: (data: SupplyFilterSetting[]) => {
            if (data) {
                const sortedSupplyFilterDefault = [...data].sort((a, b) => a.order - b.order);
                const supplyFilterTypes = sortedSupplyFilterDefault.map(supplyFilterDefault =>
                    getSupplyFilterTypeByString(supplyFilterDefault.typeName),
                );
                const filteredSupplyFilterTypes = supplyFilterTypes.filter(
                    (supplyFilterType): supplyFilterType is SupplyFilterType =>
                        supplyFilterType !== SupplyFilterType.Presale,
                );
                setDefaultFilterTypeOrder(filteredSupplyFilterTypes);
            }
        },
    });

    function getSupplyFilterDefault() {
        return client.getSupplyFilterDefault();
    }

    const staticFilterOptions: FilterItem[] = [
        {
            filterItemType: SupplyFilterType.MainGroup,
            filterOptions: getMaingroupOptions().map(maingroup => ({
                key: maingroup.key,
                code: maingroup.code,
                name: maingroup.name,
            })),
        },
        {
            filterItemType: SupplyFilterType.Marking,
            filterOptions: markingFilterOptions,
        },
        {
            filterItemType: SupplyFilterType.Presale,
            filterOptions: presaleFilterOptions,
        },
    ];
    const { lastSearchParams, setLastSearchParams } = useSearchCommandContext();

    const [filterSetMeta, setFilterSetMeta] = useState<Omit<FilterSetDetails, 'filterItems' | 'rangeFilterItems'>>({
        name: '',
        marking: false,
        presale: false,
    });

    const [filterItems, setFilterItems] = useState<FilterItem[]>([]);
    const [rangeFilterItems, setRangeFilterItems] = useState<RangeFilterItem[]>([]);

    const [allFilterItems, setAllFilterItems] = useState<FilterItem[]>(staticFilterOptions);

    useSupplyFilterType(SupplyFilterType.ProductGroup, () => client.getProductGroups(), updateStaticFilterOptions);
    useSupplyFilterType(SupplyFilterType.Supplier, () => client.getSuppliers(), updateStaticFilterOptions);
    useSupplyFilterType(SupplyFilterType.Package, () => client.getPackages(), updateStaticFilterOptions);
    useSupplyFilterType(SupplyFilterType.Quality, () => client.getQualities(), updateStaticFilterOptions);
    useSupplyFilterType(SupplyFilterType.MainColor, () => client.getMainColors(), updateStaticFilterOptions);
    useSupplyFilterType(SupplyFilterType.Product, () => client.getProducts(), updateStaticFilterOptions);
    useSupplyFilterType(SupplyFilterType.PotSize, () => client.getSortedPotSizes(), updateStaticFilterOptions);
    useSupplyFilterType(
        SupplyFilterType.FlowerStemLength,
        () => client.getSortedFlowerStemLengths(),
        updateStaticFilterOptions,
    );
    useSupplyFilterType(SupplyFilterType.Weight, () => client.getSortedWeights(), updateStaticFilterOptions);
    useSupplyFilterType(SupplyFilterType.Certificates, () => client.getCertificates(), updateStaticFilterOptions);
    useSupplyFilterType(SupplyFilterType.Clock, () => client.getClocks(), updateStaticFilterOptions);
    useSupplyFilterType(SupplyFilterType.PlantHeight, () => client.getSortedPlantHeights(), updateStaticFilterOptions);
    useSupplyFilterType(
        SupplyFilterType.CountryOfOrigin,
        () => client.getCountriesOfOrigin(),
        updateStaticFilterOptions,
    );
    useSupplyFilterType(
        SupplyFilterType.AuctionLocation,
        () => client.getAuctionLocations(),
        updateStaticFilterOptions,
    );
    useSupplyFilterType(SupplyFilterType.MaturityStage, () => client.getMaturityStages(), updateStaticFilterOptions);
    useSupplyFilterType(
        SupplyFilterType.MinimumBranchesPerPlant,
        () => client.getMinimumBranchesPerPlant(),
        updateStaticFilterOptions,
    );
    useSupplyFilterType(SupplyFilterType.AuctionGroup, () => client.getAuctionGroups(), updateStaticFilterOptions);

    const saveFilterSet = useMutation(() => client.saveFilterSet(mapFromFilterSet()), {
        onError: error => {
            handleApiGetError(error);
        },
    });

    const { kvvVisible } = useFeatureFlags();

    const { userSettings, isInitialized } = useUserSettings();

    const [isDefaultfilter, setIsDefaultfilter] = useState(false);

    // MAPPING FUNCTIONS
    function saveFilterSetToState(filterSetDto: FilterSetDetails) {
        const filterItemsToMap: FilterItem[] = filterSetDto.filterItems.map(filterItem => ({ ...filterItem }));

        setFilterSetMeta({
            filterSetId: filterSetDto.filterSetId,
            name: filterSetDto.name,
            marking: filterSetDto.marking,
            presale: filterSetDto.presale,
        });

        setFilterItems([
            ...filterItemsToMap,
            {
                filterItemType: SupplyFilterType.Marking,
                filterOptions: filterSetDto.marking ? markingFilterOptions : [],
            },
            {
                filterItemType: SupplyFilterType.Presale,
                filterOptions: filterSetDto.presale ? presaleFilterOptions : [],
            },
        ]);

        setRangeFilterItems(filterSetDto.rangeFilterItems ?? []);
    }

    function mapFromFilterSet(): FilterSetDetails {
        function getSelectedOptionKeys(filterType: SupplyFilterType): string[] {
            return getSelectedFilterOptions(filterType).map(option => option.key) ?? [];
        }

        function getSelectedOptions(filterItem: FilterItem, availableOptions: ReturnType<typeof getAllFilterOptions>) {
            const selectedKeys = new Set(getSelectedOptionKeys(filterItem.filterItemType));
            return availableOptions.filter(option => selectedKeys.has(option.key));
        }

        function getAvailableSelectedOptions(filterItem: FilterItem) {
            const availableOptions = getAllFilterOptions(filterItem.filterItemType);
            return getSelectedOptions(filterItem, availableOptions);
        }

        const mappedFilterItems = filterItems.map(
            (filterItem): FilterItem => ({
                filterItemType: filterItem.filterItemType,
                //only post existing items to the server; obsolete items will be removed
                filterOptions: getAvailableSelectedOptions(filterItem),
            }),
        );

        setSearchParams(mappedFilterItems, rangeFilterItems);

        return {
            filterSetId: filterSetMeta.filterSetId,
            name: filterSetMeta.name,
            marking: getSelectedOptionKeys(SupplyFilterType.Marking).length !== 0,
            presale: kvvVisible ? getSelectedOptionKeys(SupplyFilterType.Marking).length !== 0 : false,
            filterItems: mappedFilterItems,
            rangeFilterItems,
        };
    }

    // END MAPPING FUNCTIONS

    function setSearchParams(filters: FilterItem[], rangeFilters: RangeFilterItem[]) {
        if (lastSearchParams) {
            const searchObject = new URLSearchParams(lastSearchParams);
            const selectedFilterSet = searchObject.get('filterSet');

            if (selectedFilterSet === id) {
                const searchFilters: SearchFilterItem[] = filters
                    .map(filter => ({
                        filterItemType: filter.filterItemType,
                        filterOptionKeys: filter.filterOptions.map(option => option.key),
                    }))
                    .filter(filter => filter.filterOptionKeys.length > 0);
                const searchRangeFilters: SearchRangeFilterItem[] = rangeFilters
                    .map(filter => ({
                        supplyRangeFilterType: filter.filterItemType,
                        min: filter.min,
                        max: filter.max,
                    }))
                    .filter(filter => !!filter.min || !!filter.max);
                searchObject.set('filters', JSON.stringify(searchFilters));
                searchObject.set('rangeFilters', JSON.stringify(searchRangeFilters));
                setLastSearchParams(searchObject.toString());
            }
        }
    }

    function goBack() {
        navigate(-1);
    }

    async function saveFilterSetRequested() {
        const filterSetResult = await saveFilterSet.mutateAsync();

        dirtyMarker.markClean();
        showFilterSetMessages(filterSetResult);
    }

    function showFilterSetMessages(filterSetResult: ActionResult) {
        if (filterSetResult.isSuccessful) {
            SnackbarManager.show(getFilterSetMessagesTranslation('filterSetSaved'));

            navigate('/filter-sets');
            return;
        }
        if (filterSetResult.errorCodes && filterSetResult.errorCodes.length > 0) {
            SnackbarManager.showError(
                filterSetResult.errorCodes.map(errorCode => getFilterSetMessagesTranslation(errorCode)).join(' '),
            );
            return;
        }

        SnackbarManager.showError(getFilterSetMessagesTranslation('technicalException'));
    }

    function updateStaticFilterOptions(filterType: SupplyFilterType, options: FilterOption[]) {
        const filterOptionsForType = allFilterItems.find(o => o.filterItemType === filterType);

        if (filterOptionsForType) {
            filterOptionsForType.filterOptions = options ?? [];
        } else {
            setAllFilterItems(previousValue => [
                ...previousValue,
                { filterItemType: filterType, filterOptions: options },
            ]);
        }
    }

    function updateFilterItem(supplyFilterType: SupplyFilterType, filterOptions: FilterOption[]) {
        // Make a copy of the filterset so that we trigger a state change on update of one of the filteritems
        const updatedFilterItems = [...filterItems];
        const filterItem = updatedFilterItems.find(x => x.filterItemType === supplyFilterType);

        if (filterItem) {
            filterItem.filterOptions = filterOptions ?? [];
        } else {
            updatedFilterItems.push({ filterItemType: supplyFilterType, filterOptions: filterOptions ?? [] });
        }

        setFilterItems(updatedFilterItems);
    }

    function updateRangeFilter(supplyRangeFilterType: SupplyRangeFilterType, min?: number, max?: number) {
        const updatedRangeFilters = [...rangeFilterItems];
        const rangeFilterItem = updatedRangeFilters.find(filter => filter.filterItemType === supplyRangeFilterType);

        if (!min && !max) {
            const rangeFilterItemsWithoutFilterType = updatedRangeFilters.filter(
                filter => filter.filterItemType !== supplyRangeFilterType,
            );

            setRangeFilterItems(rangeFilterItemsWithoutFilterType);

            return;
        }

        if (rangeFilterItem) {
            rangeFilterItem.min = min ?? undefined;
            rangeFilterItem.max = max ?? undefined;
        } else {
            updatedRangeFilters.push({
                filterItemType: supplyRangeFilterType,
                min: min ?? undefined,
                max: max ?? undefined,
            });
        }

        setRangeFilterItems(updatedRangeFilters);
    }

    function getAllFilterOptions(filterType: SupplyFilterType): FilterOption[] {
        return allFilterItems.find(o => o.filterItemType === filterType)?.filterOptions ?? [];
    }

    function getSelectedFilterOptions(supplyFilterType: SupplyFilterType): FilterOption[] {
        return filterItems.find(filterItem => filterItem.filterItemType === supplyFilterType)?.filterOptions ?? [];
    }

    function getRangeFilter(supplyFilterType: SupplyFilterType) {
        const rangeFilterType = getSupplyRangeFilterType(supplyFilterType) as SupplyRangeFilterType;

        return rangeFilterItems?.find(filterItem => filterItem.filterItemType === rangeFilterType);
    }

    useEffect(() => {
        const effectId = id;

        if (effectId && effectId !== 'new') {
            client.getFilterSet(id).then(
                data => {
                    saveFilterSetToState(data);
                },
                error => handleApiGetError(error),
            );
        }
    }, []);

    useEffect(() => {
        setIsDefaultfilter(userSettings.defaultFilterSetId === id);
    }, [isInitialized]);

    return (
        <>
            <Form onValidSubmit={saveFilterSetRequested}>
                <PageHeader
                    title={t(`${namespaces.supply}:clockSupplyFilter.filterDetail.header`)}
                    onBackClick={goBack}
                />
                {filterSetMeta && (
                    <div className={sharedClasses.flexiblePage}>
                        <div className={classes.pageTopBar}>
                            <div className={classes.nameContainer}>
                                <DefaultFormGroup
                                    value={filterSetMeta.name}
                                    onChange={e => {
                                        dirtyMarker.markDirty();
                                        setFilterSetMeta(previousValue => ({
                                            ...previousValue,
                                            name: e.target.value,
                                        }));
                                    }}
                                    validations={[new IsRequired(), new MaxLength(200)]}
                                    fullWidth
                                    placeholder={t(`${namespaces.supply}:clockSupplyFilter.filterDetail.filterName`)}
                                />
                                {isDefaultfilter && (
                                    <div className={classes.box}>
                                        {t(`${namespaces.supply}:clockSupplyFilter.filterDetail.defaultFilter`)}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div data-testid='staticFilterRow'>
                            {defaultFilterTypeOrder.map(supplyFilterType => {
                                const allOptions = getAllFilterOptions(supplyFilterType);
                                const selectedOptions = getSelectedFilterOptions(supplyFilterType);
                                const rangeFilter = filterPanelSettings[supplyFilterType]?.includes(
                                    FilterPanelSetting.isRangeFilter,
                                )
                                    ? getRangeFilter(supplyFilterType)
                                    : undefined;

                                return (
                                    <StaticFilterRow
                                        key={supplyFilterType}
                                        name={getSupplyFilterTypeTranslation(supplyFilterType)}
                                        filterType={supplyFilterType}
                                        activeOptionCount={selectedOptions.length}
                                        filterOptions={allOptions}
                                        selectedFilterOptions={selectedOptions}
                                        showFilterOptionCode={filterPanelSettings[supplyFilterType]?.includes(
                                            FilterPanelSetting.showFilterOptionsCode,
                                        )}
                                        rangeFilter={rangeFilter}
                                        filterOptionsChanged={filterOptions => {
                                            dirtyMarker.markDirty();
                                            updateFilterItem(supplyFilterType, filterOptions);
                                        }}
                                        rangeFilterChanged={(supplyRangeFilterType, min, max) => {
                                            dirtyMarker.markDirty();
                                            updateRangeFilter(supplyRangeFilterType, min, max);
                                        }}
                                    />
                                );
                            })}
                        </div>

                        <div className={classes.actionButtonContainer}>
                            <ResponsiveButton
                                variant='contained'
                                color='primary'
                                type='submit'
                                data-testid='saveFilterSet'
                                disabled={!dirtyMarker.dirty}
                            >
                                {t(`${namespaces.general}:buttons.save`)}
                            </ResponsiveButton>
                        </div>
                    </div>
                )}
            </Form>
            <LeaveBlocker dirtyMarker={dirtyMarker} />
        </>
    );
}
