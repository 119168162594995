import { useEffect, useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@floriday/floriday-icons';
import { Box, Button, Link, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import config from '@config';
import { IUser } from '@features/Auth/Contracts/IUser';
import { getUserAccount } from '@features/Auth/auth';
import i18n, { namespaces, SupportedLocale } from '@root/i18n';
import { useActivityTracker } from '@store/Providers/ActivityTrackerProvider';
import { useFeatureFlags } from '@store/Providers/FeatureFlagsProvider';
import { useKOAContext } from '@store/Providers/KOAProvider';
import { usePresetContext } from '@store/Providers/PresetsProvider';
import { loadFromLocalStorage } from '@utils/LocalStorageUtils';
import { useStyles } from './ReadOnlyFloaterStyles';

export default function ReadOnlyFloater() {
    const { t } = useTranslation(namespaces.general);
    const { classes } = useStyles();
    const { selectedPreset, readOnlyFloaterOpen, setReadOnlyFloaterOpen } = usePresetContext();
    const { koaInProgress } = useKOAContext();
    const { trackPageVisitedActivity } = useActivityTracker();
    const { pathname } = useLocation();
    const [userAccount, setUserAccount] = useState<IUser>();
    const { isOrganizationAdministrator } = useFeatureFlags();
    const navigate = useNavigate();
    const [hideReadOnlyFloater, setHideReadOnlyFloater] = useState(loadFromLocalStorage('hideReadOnlyFloater'));

    useEffect(() => {
        async function getUser() {
            const account = await getUserAccount();
            if (account) {
                setUserAccount(account);
            }
        }

        getUser();
    }, []);

    function getTitle() {
        if (koaInProgress) {
            return t('readOnlyFloater.inProgressHeader');
        }

        return t('readOnlyFloater.header');
    }

    function getDescription() {
        if (koaInProgress) {
            return t('readOnlyFloater.inProgressText');
        }

        return t('readOnlyFloater.text');
    }

    function getButtonText() {
        if (isOrganizationAdministrator && koaInProgress) {
            return t('connectKoa.connectAnotherKOA');
        }
        if (isOrganizationAdministrator) {
            return t('connectKoa.connectKOA');
        }

        return t('readOnlyFloater.callToAction');
    }

    function onHideFloater() {
        localStorage.setItem('hideReadOnlyFloater', 'true');
        setHideReadOnlyFloater(true);
    }

    return (
        !hideReadOnlyFloater &&
        userAccount?.isSubCustomer &&
        selectedPreset?.isReadOnly &&
        !pathname.includes('connect-koa') && (
            <Paper
                className={`${classes.container} ${koaInProgress ? classes.largeHeader : ''} ${readOnlyFloaterOpen ? classes.open : ''}`}
                id='readOnlyFloater'
                tabIndex={0}
                onMouseDown={e => e.preventDefault()}
                onAnimationEnd={e => {
                    e.currentTarget.blur();
                }}
            >
                <Box className={classes.content}>
                    <Box className={classes.header} data-testid='readOnlyFloaterHeader'>
                        <Typography className={classes.title}>{getTitle()}</Typography>
                        {readOnlyFloaterOpen ? (
                            <ArrowDownIcon
                                onClick={() => setReadOnlyFloaterOpen(!readOnlyFloaterOpen)}
                                className={classes.arrowButton}
                            />
                        ) : (
                            <ArrowUpIcon
                                onClick={() => setReadOnlyFloaterOpen(!readOnlyFloaterOpen)}
                                className={classes.arrowButton}
                            />
                        )}
                    </Box>
                    <Typography className={classes.description} data-testid='readOnlyFloaterText'>
                        {getDescription()}
                    </Typography>
                    <Button
                        className={classes.button}
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={e => {
                            e.stopPropagation();
                            if (isOrganizationAdministrator) {
                                navigate('/connect-koa');
                            } else {
                                const userAgent = navigator.userAgent.toLowerCase();
                                trackPageVisitedActivity('connect-koa-account-help');
                                window.open(
                                    config.connectKoaAccountHelpUrl[i18n.language as SupportedLocale],
                                    userAgent.includes('iphone') ? '_blank' : 'connectKoaAccount',
                                );
                            }
                        }}
                    >
                        {getButtonText()}
                    </Button>
                    <Link
                        component='button'
                        style={{ fontWeight: 'unset' }}
                        color='#fff'
                        underline='always'
                        onClick={onHideFloater}
                        data-testid='resetAll'
                    >
                        {t('readOnlyFloater.doNotShowMessage')}
                    </Link>
                </Box>
            </Paper>
        )
    );
}
