import { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useMarkingAccounts } from '@features/Supply/Hooks/useMarkingAccounts';
import { useSaveUserGroup } from '@features/Supply/Hooks/useSaveUserGroup';
import { useUserGroup } from '@features/Supply/Hooks/useUserGroup';
import { namespaces } from '@root/i18n';
import { useUserSettings } from '@store/Providers/UserSettingsProvider';
import { useStyles } from './MarkForUsersSettingStyles';

export default function MarkForUsersSetting() {
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.general, { keyPrefix: 'settings' });
    const { data: markingAccounts } = useMarkingAccounts();
    const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
    const { saveUserGroup } = useSaveUserGroup();
    const { data: userGroup } = useUserGroup();
    const { userSettings, updateUserSettings } = useUserSettings();

    const sortedAccounts = markingAccounts?.accounts.sort((a, b) => a.localeCompare(b)) ?? [];

    function onCheckboxChange(account: string, checked: boolean) {
        const updateSelectedAccounts = checked
            ? [...selectedAccounts, account]
            : selectedAccounts.filter(a => a !== account);
        setSelectedAccounts(updateSelectedAccounts);
        saveUserGroup.mutate(updateSelectedAccounts);

        if (!userSettings?.markForGroup) {
            updateUserSettings({ ...userSettings, markForGroup: true });
        }
    }

    useEffect(() => {
        setSelectedAccounts(userGroup?.users ?? []);
    }, [userGroup]);

    return (
        <Box className={classes.container}>
            <Typography variant='h4'>{t('markFor')}</Typography>
            <Typography className={classes.description}>{t('chooseOneOrMore')}</Typography>
            <FormGroup className={classes.accountList}>
                {sortedAccounts.map(account => (
                    <FormControlLabel
                        key={account}
                        className={classes.accountListItem}
                        control={
                            <Checkbox
                                checked={selectedAccounts.includes(account)}
                                value={account}
                                onChange={(_, checked) => onCheckboxChange(account, checked)}
                            />
                        }
                        label={account}
                    />
                ))}
            </FormGroup>
        </Box>
    );
}
