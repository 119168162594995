import { Box, Typography } from '@mui/material';

import MarkButtonContainer from '@features/Supply/Components/Marking/MarkButton/MarkButtonContainer';
import { addMarkingUser, deleteMarkingUser } from '@store/Actions/markingUsersActions';
import { useMarkingUsersContext } from '@store/Providers/MarkingUsersProvider';
import { useStyles } from './AccountsMarkingListStyles';

interface IProps {
    readonly accounts: string[];
    readonly onChange: (selected: boolean, username: string) => void;
    readonly supplyLineId: string;
}

export default function AccountsMarkingList(props: IProps) {
    const { accounts, onChange, supplyLineId } = props;
    const { classes } = useStyles();
    const {
        state: { markings: markingsInContext },
        dispatch,
    } = useMarkingUsersContext();
    const supplyLineMarkings = markingsInContext[supplyLineId] ?? [];

    return (
        <Box className={classes.accountsContainer}>
            {accounts.map(username => (
                <Box key={username} className={classes.accountsItem} data-testid='markingAccount'>
                    <MarkButtonContainer
                        onChange={selected => {
                            if (selected) dispatch(addMarkingUser(supplyLineId, username));
                            else dispatch(deleteMarkingUser(supplyLineId, username));
                            onChange(selected, username);
                        }}
                        selected={!!supplyLineMarkings?.includes(username)}
                    />
                    <Typography className={classes.accountsItemLabel}>{username}</Typography>
                </Box>
            ))}
        </Box>
    );
}
