import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';

import config from '@config';
import ConnectKOADataForm from '@features/KOAConnection/Components/ConnectKOADataForm/ConnectKOADataForm';
import ConnectKOAInProgress from '@features/KOAConnection/Components/ConnectKOAInProgress/ConnectKOAInProgress';
import ConnectKOAStepInfo from '@features/KOAConnection/Components/ConnectKOAStepInfo/ConnectKOAStepInfo';
import { namespaces } from '@root/i18n';
import { useActivityTracker } from '@store/Providers/ActivityTrackerProvider';
import { useBreakpointContext } from '@store/Providers/BreakpointProvider';
import { useFeatureFlags } from '@store/Providers/FeatureFlagsProvider';
import { useStyles } from './ConnectKOAStyles';

export default function ConnectKOA() {
    const { t } = useTranslation(namespaces.general);
    const { trackEvent } = useIntercom();
    const { trackPageVisitedActivity } = useActivityTracker();
    const { isOrganizationAdministrator } = useFeatureFlags();
    const { isBreakpointSmall } = useBreakpointContext();
    const { classes } = useStyles();
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (isOrganizationAdministrator === false) {
            navigate('/supply-overview');
        }
        if (config.intercom.enabled) {
            trackEvent('RFH Pre-Auction', {
                page: 'connect-koa',
            });
        }
        trackPageVisitedActivity('connect-koa');
    }, []);

    return (
        isOrganizationAdministrator && (
            <Box className={classes.container}>
                {isBreakpointSmall ? (
                    <Typography variant='h3' className={classes.mobileMessage}>
                        {t('connectKoa.useDesktop')}
                    </Typography>
                ) : (
                    <>
                        <Box className={classes.leftSection}>
                            <ConnectKOAStepInfo currentStep={currentStep} />
                        </Box>
                        <Box className={classes.rightSection}>
                            <Box className={classes.content}>
                                {currentStep === 0 && <ConnectKOADataForm setStep={setCurrentStep} />}
                                {currentStep === 1 && <ConnectKOAInProgress />}
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        )
    );
}
