import { useMutation } from '@tanstack/react-query';

import AuctionPreparationClient from '@api/AuctionPreparationClient';

export function useSaveUserGroup() {
    const client = AuctionPreparationClient.instance.getApiClient();

    const saveUserGroup = useMutation(async (userGroup: string[]) => client.saveUserGroup({ users: userGroup }));

    return { saveUserGroup };
}
