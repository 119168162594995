import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: '30px',
            width: 'auto',
        },
    },
    icon: {
        color: theme.palette.grey[600],
    },
    selected: {
        color: theme.palette.primary.main,
    },
    alert: {
        alignItems: 'center',
        marginRight: theme.spacing(2),
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
        fontSize: '14px',
        border: '1px solid white',
        div: {
            lineHeight: 1,
        },
        svg: {
            width: '20px',
        },
    },
}));
