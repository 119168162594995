import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    container: {
        bottom: 0,
        position: 'fixed',
        right: 130,
        [theme.breakpoints.down('sm')]: {
            right: 0,
        },
        transform: 'translateY(calc(100% - 62px))',
        borderRadius: theme.spacing(1, 1, 0, 0),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        zIndex: 1,
        width: '300px',
        '& h4': {
            color: 'white',
        },
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        whiteSpace: 'pre-line',
    },
    largeHeader: {
        transform: 'translateY(calc(100% - 78px))',
    },
    open: {
        transform: 'translateY(0)',
        '&:focus:before': {
            content: '""',
            position: 'fixed',
            width: 'inherit',
            height: '100%',
            animation: 'ripple .5s ease-in-out',
            borderRadius: theme.spacing(1, 1, 0, 0),
            zIndex: -1,
            '@keyframes ripple': {
                '0%': {
                    backgroundColor: 'transparent',
                },
                '100%': {
                    backgroundColor: theme.palette.primary.main,
                    transform: 'scale(1.5)',
                    opacity: 0,
                },
            },
        },
    },
    content: {
        display: 'flex',
        gap: theme.spacing(2),
        flexDirection: 'column',
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        height: '100%',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontWeight: 700,
        color: theme.palette.primary.contrastText,
    },
    arrowButton: {
        width: '14px',
        cursor: 'pointer',
    },
    button: {
        marginTop: 'auto',
        backgroundColor: 'white',
        color: theme.palette.primary.main,
    },
    description: {
        paddingBottom: theme.spacing(1),
        color: theme.palette.primary.contrastText,
    },
}));
