import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        margin: theme.spacing(2, 0),
    },
    accountList: {
        maxHeight: '250px',
        overflow: 'auto',
        flexDirection: 'row',
    },
    accountListItem: {
        width: '100%',
    },
    saveButton: {
        float: 'right',
    },
    description: {
        fontStyle: 'italic',
    },
}));
