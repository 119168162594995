import { brandPurple, brandGreen } from '@floriday/floriday-ui/es/Styles/colors';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    marked: {
        minHeight: '24px',
        '& .MuiButton-startIcon': {
            margin: 0,
        },
    },
    badge: {
        '& .MuiBadge-badge': {
            width: theme.spacing(1.5),
            height: theme.spacing(1.5),
            marginTop: theme.spacing(0.5),
            minWidth: 'auto',
            backgroundColor: brandGreen.main,
            color: '#FFF',
        },
    },
    icon: {
        color: brandPurple.main,
        width: '16px',
        height: '16px',
    },
    markedDisabled: {
        color: brandPurple.light,
        width: '16px',
        height: '16px',
    },
    popover: {
        maxWidth: '400px',
        'td:last-child': { wordBreak: 'break-word' },
    },
    button: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
        padding: '0',
        borderRadius: '0',
        [theme.breakpoints.down('sm')]: {
            height: '40px',
            width: '40px',
        },
        width: '24px',
        height: '24px',
    },
}));
