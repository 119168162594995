import { useEffect, useState, PropsWithChildren } from 'react';
import { LoadingPage } from '@floriday/floriday-ui';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router';

import AppsPortalClient from '@api/AppsPortalClient';
import LoginError from './LoginError';
import { oktaAuthClient, hasTokenExpired, getUserAccount } from '../auth';

export default function AuthContainer(props: Readonly<PropsWithChildren>) {
    const { children } = props;
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [paaAccessIsOk, setPaaAccessIsOk] = useState<boolean>(false);
    const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
    const location = useLocation();
    useEffect(() => {
        async function checkAuth() {
            await oktaAuthClient.start();

            const isAuthenticated = await oktaAuthClient.isAuthenticated();

            if (isAuthenticated) {
                const userInfo = await getUserAccount();
                Sentry.setTag('userName', userInfo.username);

                const tokenExpired = await hasTokenExpired();
                if (tokenExpired) {
                    await oktaAuthClient.stop();
                    await oktaAuthClient.signInWithRedirect();
                    return;
                }
                setIsLoggedIn(true);

                const appsPortalClient = AppsPortalClient.instance.getApiClient();
                const isPaaAccessOk = await appsPortalClient.isPaaAccessOk();
                setPaaAccessIsOk(isPaaAccessOk);
                setIsPageLoaded(true);
            } else {
                oktaAuthClient.setOriginalUri(`${location.pathname}${location.search}`);
                oktaAuthClient.signInWithRedirect();
            }
        }

        checkAuth();
    }, []);
    if (!isPageLoaded) {
        return <LoadingPage />;
    }
    if (!paaAccessIsOk) {
        return (
            <LoginError
                error={{
                    name: '',
                    message: 'User is not assigned to the client application.',
                }}
            />
        );
    }
    if (isLoggedIn) {
        return <>{children}</>;
    }
    return <LoadingPage />;
}
