import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

import AppsPortalClient from '@api/AppsPortalClient';
import { handleApiGetError } from '@api/helper';
import { isGenericError } from '@contracts/IGenericError';
import { usePresetContext } from '@store/Providers/PresetsProvider';

interface ActivityTrackerContextValue {
    trackPageVisitedActivity: (page: string) => Promise<void>;
    trackMarkedActivity: (target: string) => Promise<void>;
    trackChangedPreference: (target: string) => Promise<void>;
    trackPresaleBuyRedirectedActivity: () => Promise<void>;
    trackConnectKOARequestedActivity: () => Promise<void>;
}

const ActivityTrackerContext = createContext<ActivityTrackerContextValue>({
    trackPageVisitedActivity: async () => undefined,
    trackMarkedActivity: async () => undefined,
    trackChangedPreference: async () => undefined,
    trackPresaleBuyRedirectedActivity: async () => undefined,
    trackConnectKOARequestedActivity: async () => undefined,
});

const client = AppsPortalClient.instance.getApiClient();

export function ActivityTrackerProvider(props: Readonly<PropsWithChildren>) {
    const { children } = props;
    const { selectedPreset } = usePresetContext();
    const [notAuthorizedOrganizationIds, setNotAuthorizedOrganizationIds] = useState<string[]>([]);

    const value = useMemo<ActivityTrackerContextValue>(
        () => ({
            trackPageVisitedActivity,
            trackMarkedActivity,
            trackChangedPreference,
            trackPresaleBuyRedirectedActivity,
            trackConnectKOARequestedActivity,
        }),
        [notAuthorizedOrganizationIds],
    );

    async function trackPageVisitedActivity(page: string) {
        const readOnly = selectedPreset?.isReadOnly ? 'read-only-' : '';
        await sendActivity(`pre-auction-visited-${readOnly}${page}`);
    }

    async function trackMarkedActivity(target: string) {
        await sendActivity(`pre-auction-marked-${target}`);
    }

    async function trackChangedPreference(target: string) {
        await sendActivity(`pre-auction-changed-preference-${target}`);
    }
    async function trackPresaleBuyRedirectedActivity() {
        await sendActivity(`pre-auction-redirected-buy-pre-sale`);
    }

    async function trackConnectKOARequestedActivity() {
        await sendActivity(`pre-auction-requested-koa-connection`);
    }

    async function sendActivity(activity: string) {
        try {
            if (selectedPreset && !notAuthorizedOrganizationIds.includes(selectedPreset.organizationId)) {
                await client.trackActivityForApp({ event: activity });
                return true;
            }
            return false;
        } catch (error) {
            if (isGenericError(error) && error.status === 403) {
                if (selectedPreset && !notAuthorizedOrganizationIds.includes(selectedPreset.organizationId)) {
                    setNotAuthorizedOrganizationIds(prev => [...prev, selectedPreset.organizationId]);
                }
            } else {
                handleApiGetError(error, false);
            }
            return false;
        }
    }

    return <ActivityTrackerContext.Provider value={value}>{children}</ActivityTrackerContext.Provider>;
}

export function useActivityTracker(): ActivityTrackerContextValue {
    return useContext(ActivityTrackerContext);
}
