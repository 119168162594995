import { useState } from 'react';
import { SupplyLineIcon } from '@floriday/floriday-icons';
import {
    AppBar as FloridayAppBar,
    SidebarDrawer,
    SidebarNavigation,
    SidebarNavigationItems,
    SidebarNavigationItem,
} from '@floriday/floriday-ui';
import { greyDark } from '@floriday/floriday-ui/es/Styles/colors';
import { useTranslation } from 'react-i18next';

import { namespaces } from '@root/i18n';
import ClockSupplyLogoIcon from '@resources/Icons/ClockSupplyLogoIcon';
import ClockSupplyLogoIconWithoutText from '@resources/Icons/ClockSupplyLogoIconWithoutText';
import InspectScanIcon from '@resources/Icons/InspectScanIcon';
import { useBreakpointContext } from '@store/Providers/BreakpointProvider';
import { useFeatureFlags } from '@store/Providers/FeatureFlagsProvider';
import { useStyles } from './AppBarStyles';
import UserMenuContainer from '../UserMenu/UserMenuContainer';

export default function AppBar() {
    const { t } = useTranslation(namespaces.general);
    const { hasScanVisible } = useFeatureFlags();
    const { isBreakpointSmall } = useBreakpointContext();
    const { classes } = useStyles();
    const sidebarItems = [
        {
            title: t('routes.marketplace'),
            route: '/supply-overview',
            icon: (
                <SupplyLineIcon
                    className={location.pathname.includes('/supply-overview') ? classes.selected : classes.icon}
                />
            ),
            module: 'supply-overview',
        },
    ];

    if (hasScanVisible && isBreakpointSmall) {
        sidebarItems.push({
            title: t('routes.scan'),
            route: '/scan',
            icon: <InspectScanIcon className={location.pathname.includes('/scan') ? classes.selected : classes.icon} />,
            module: 'scan',
        });
    }

    const [currentSidebarItem, setCurrentSidebarItem] = useState<string>(
        location.pathname.includes('scan')
            ? 'scan'
            : (sidebarItems.find(sidebarItem => location.pathname.includes(sidebarItem.module))?.module ??
                  'supply-overview'),
    );

    const [menuOpen, setMenuOpen] = useState(false);

    function handleOpenModule(module: string) {
        setCurrentSidebarItem(module);
    }

    function handleCloseSidebar() {
        setMenuOpen(false);
    }

    return (
        <>
            <FloridayAppBar
                menuButtonVisibility='always'
                menuButtonActive={menuOpen}
                onMenuButtonClick={() => setMenuOpen(!menuOpen)}
                logo={
                    isBreakpointSmall ? (
                        <ClockSupplyLogoIconWithoutText data-testid='appTitle' />
                    ) : (
                        <ClockSupplyLogoIcon data-testid='appTitle' />
                    )
                }
                rightContent={<UserMenuContainer />}
                classes={{ appbar: classes.header, logo: classes.logo }}
            />
            <SidebarDrawer open={menuOpen} variant='temporary' onClose={handleCloseSidebar}>
                <SidebarNavigation>
                    <SidebarNavigationItems
                        activeModule={currentSidebarItem}
                        onOpenModule={handleOpenModule}
                        onRequestClose={handleCloseSidebar}
                    >
                        {sidebarItems.map(sidebarItem => (
                            <SidebarNavigationItem
                                key={sidebarItem.title}
                                title={sidebarItem.title}
                                color={greyDark[800]}
                                icon={sidebarItem.icon}
                                href={sidebarItem.route}
                                module={sidebarItem.module}
                            />
                        ))}
                    </SidebarNavigationItems>
                </SidebarNavigation>
            </SidebarDrawer>
        </>
    );
}
