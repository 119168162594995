import { SnackbarSingleton } from '@floriday/floriday-ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Outlet } from 'react-router';

import { IGenericError } from '@contracts/IGenericError';
import AuthContainer from '@features/Auth/Components/AuthContainer';
import ReadOnlyFloater from '@features/Auth/Components/ReadOnlyFloater';
import AppBar from '@root/Components/AppBar/AppBar';
import AppLayout from '@root/Components/AppLayout/AppLayout';
import CustomErrorBoundary from '@root/Components/CustomErrorBoundary/CustomErrorBoundary';
import { ActivityTrackerProvider } from '@store/Providers/ActivityTrackerProvider';
import { BreakpointProvider } from '@store/Providers/BreakpointProvider';
import { FeatureFlagsProvider } from '@store/Providers/FeatureFlagsProvider';
import IntercomProvider from '@store/Providers/IntercomProvider';
import { KOAProvider } from '@store/Providers/KOAProvider';
import { MarkingUsersProvider } from '@store/Providers/MarkingUsersProvider';
import { MarkingsProvider } from '@store/Providers/MarkingsProvider';
import { PresetsProvider } from '@store/Providers/PresetsProvider';
import { SearchCommandProvider } from '@store/Providers/SearchCommandProvider';
import { UserAgreementProvider } from '@store/Providers/UserAgreementProvider';
import { UserSettingsProvider } from '@store/Providers/UserSettingsProvider';
import { useStyles } from './FrameStyles';

export default function Frame() {
    const { classes } = useStyles();

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false, // If not turned off a query will be triggered every time focus changes
                retry: (failureCount, error) => failureCount <= 3 && (error as IGenericError).status !== 401,
            },
        },
    });

    return (
        <BreakpointProvider>
            <AuthContainer>
                <QueryClientProvider client={queryClient}>
                    <SnackbarSingleton autoHideDuration={10000} />
                    <PresetsProvider>
                        <FeatureFlagsProvider>
                            <UserAgreementProvider>
                                <UserSettingsProvider>
                                    <ActivityTrackerProvider>
                                        <IntercomProvider>
                                            <KOAProvider>
                                                <AppBar />
                                                <div className={classes.content}>
                                                    <CustomErrorBoundary>
                                                        <MarkingsProvider>
                                                            <MarkingUsersProvider>
                                                                <SearchCommandProvider>
                                                                    <AppLayout>
                                                                        <Outlet />
                                                                    </AppLayout>
                                                                </SearchCommandProvider>
                                                            </MarkingUsersProvider>
                                                        </MarkingsProvider>
                                                    </CustomErrorBoundary>
                                                </div>
                                                <ReadOnlyFloater />
                                            </KOAProvider>
                                        </IntercomProvider>
                                    </ActivityTrackerProvider>
                                </UserSettingsProvider>
                            </UserAgreementProvider>
                        </FeatureFlagsProvider>
                    </PresetsProvider>
                </QueryClientProvider>
            </AuthContainer>
        </BreakpointProvider>
    );
}
