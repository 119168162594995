import { CloseIcon } from '@floriday/floriday-icons';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { namespaces } from '@root/i18n';
import { useBreakpointContext } from '@store/Providers/BreakpointProvider';
import { useUserSettings } from '@store/Providers/UserSettingsProvider';
import MarkDirectlySetting from './Components/MarkDirectlySetting/MarkDirectlySetting';
import MarkForUsersSetting from './Components/MarkForUsersSetting/MarkForUsersSetting';
import { useStyles } from './SettingsStyles';

interface IProps {
    readonly showDialog: boolean;
    readonly onCancel: () => void;
}

export default function Settings({ showDialog, onCancel }: IProps) {
    const { classes } = useStyles();
    const { t } = useTranslation(namespaces.general);
    const { isBreakpointSmall } = useBreakpointContext();
    const { userSettings, updateUserSettings } = useUserSettings();
    const queryClient = useQueryClient();

    function cancel() {
        queryClient.resetQueries({ queryKey: ['userGroup'] });
        onCancel();
    }

    function onMarkDirectlyChange(checked: boolean) {
        const updatedUserSettings = {
            ...userSettings,
            markDirectlyOnScan: checked,
        };

        updateUserSettings(updatedUserSettings);
    }

    return (
        <Dialog
            fullScreen={isBreakpointSmall}
            open={showDialog}
            onClose={cancel}
            classes={{ paper: classes.orderDialog }}
        >
            <DialogTitle>
                {t('userMenu.settings')}
                <IconButton
                    className={classes.closeButton}
                    onClick={cancel}
                    size='large'
                    data-testid='settingsDialogCloseButton'
                >
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.root} data-testid='settingsDialogContent'>
                <MarkForUsersSetting />
                <MarkDirectlySetting onChange={onMarkDirectlyChange} checked={!!userSettings?.markDirectlyOnScan} />
            </DialogContent>
        </Dialog>
    );
}
